// 引入拦截器配置
import { requestInterceptors, responseInterceptors } from "./interceptors";
// 引入luch-request
import { http } from "uview-plus";
import type { HttpRequestConfig } from "@/types/http";
//  初始化请求配置
const initRequest = () => {
  // 动态判断协议
  const protocol = window.location.protocol; // 当前页面协议 (http: 或 https:)
  // 根据协议选择对应的接口地址
  const API_BASE_URL =
    protocol === "https:"
      ? import.meta.env.VITE_API_BASE_URL_SSL // 使用 HTTPS 对应的接口地址
      : import.meta.env.VITE_API_BASE_URL; // 使用 HTTP 对应的接口地址
  http.setConfig((defaultConfig: HttpRequestConfig) => {
    defaultConfig.baseURL = API_BASE_URL;
    return defaultConfig;
  });
  requestInterceptors();
  responseInterceptors();
};
export { initRequest };
